import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { ConfirmDelete, Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { TextbookModel } from "@/models";
import { Common } from "../Common";

export class SearchForm {
  unit = "";
  level = "";
  grade = "";
  active = "";
}

type response = { [key: string]: unknown };

@Component<AdminTextbookIndexController>({
  components: {
    SideNav,
    ConfirmDelete,
    Lightbox
  }
})
export default class AdminTextbookIndexController extends Vue {
  private doubleClick = false;
  private searchForm = new SearchForm();
  private textbookList: object = {};
  private num = 0;
  private pageHtml = "";
  private gradeList: object = {};
  private levelList: object = {};
  private unitList: response = {};
  private tbid = 0;
  private token = window.localStorage.getItem("adminToken") as string;

  private errorMsg = "";
  private openLB = false;
  private errNo!: number;

  public async created() {
    this.getTextbookList();
    this.gradeList = await Common.getGrade();
  }

  private async getTextbookList() {
    const item: { [key: string]: string } = {
      page: this.$route.query.page as string,
      active: this.searchForm.active,
      grade: this.searchForm.grade,
      level: this.searchForm.level,
      unit: this.searchForm.unit,
      token: this.token
    };
    const data = (await TextbookModel.getList(item)) as response;
    Common.checkToken(data.ERR_CODE as number);
    this.num = data.num as number;
    this.textbookList = data.list as object;
    this.pageHtml = data.pageHtml as string;
  }

  private async getLevel() {
    this.searchForm.level = "";
    this.searchForm.unit = "";
    const grade = this.searchForm.grade;
    this.levelList = await Common.getLevel(grade);
    this.unitList = {};
  }

  private async getUnit() {
    this.searchForm.unit = "";
    for (let i = 1; i <= 25; i++) {
      this.unitList[i] = { unit: "Unit-" + i };
    }
    this.unitList[26] = { unit: "Midterm Exam" };
    this.unitList[27] = { unit: "Final Exam" };
    this.unitList[28] = { unit: 'VIDEO Q' };
    this.unitList[29] = { unit: 'WORKSHOP' };
  }

  private delTextbook(id: number) {
    this.$emit("openConfirmDelete", true);
    this.tbid = id;
  }

  private async deleteTextbook() {
    if (!this.doubleClick) {
      this.doubleClick = true;

      const item = {
        tbid: this.tbid,
        token: this.token
      };
      this.errNo = await TextbookModel.del(item);
      this.$emit("closeConfirmDelete", false);
      Common.checkToken(this.errNo);
      this.errorMsg = ErrorMessage[this.errNo];
      this.openLB = true;
      this.doubleClick = false;
    }
  }

  private closeLB() {
    this.openLB = false;
    if (this.errNo === ErrorCode.Success) {
      this.$router.go(0);
    }
  }
}
